import React from 'react';
import { Link } from 'gatsby';
import { Layout, Menu, Row, Col } from 'antd';
import { Link as ScrollLink } from 'react-scroll';
import LightLogo from '../images/irc-logo-light.svg';
import DarkLogo from '../images/irc-logo-dark.svg';
import classNames from 'classnames';

const { Header } = Layout;

const HeaderComponent = ({ ...props }) => {
  const { children, theme } = props;
  return (
    <div className={classNames({ 'header-container': true, [theme]: !!theme })}>
      <Header>
        <div className="header-wrapper">
          <Row type="flex" align="middle" className="header-row">
            <Col>
              <h1 className="logo">
                <a href="/">
                  <img src={theme === 'dark' ? LightLogo : DarkLogo} alt="" />
                </a>
              </h1>
            </Col>
            <Col className="header-menu">
              <Menu mode="horizontal" theme={theme}>
                <Menu.Item key="1">
                  <Link to="/" activeClassName="active">
                    Home
                  </Link>
                </Menu.Item>
                <Menu.Item key="2">
                  <Link to="/about" activeClassName="active">
                    About us
                  </Link>
                </Menu.Item>
                <Menu.Item key="3">
                  <Link to="/services" activeClassName="active">
                    Services
                  </Link>
                </Menu.Item>
                <Menu.Item key="4">
                  <ScrollLink to="contact" smooth duration={100}>
                    Contact
                  </ScrollLink>
                </Menu.Item>
                <Menu.Item key="5">
                  <a href="https://irc-web-blog.netlify.app" target="_blank">
                    News
                  </a>
                </Menu.Item>
              </Menu>
            </Col>
          </Row>
        </div>
      </Header>

      <div className="header-content">{children}</div>
    </div>
  );
};

export default HeaderComponent;
