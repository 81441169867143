import React, { Component } from 'react';
import { Form, Input, Button } from 'antd';
import { Events } from 'react-scroll';

const FormItem = Form.Item;
const { TextArea } = Input;

const encode = (data) => {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&');
};

class ContactForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showForm: false,
    };

    this.showForm = this.showForm.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    Events.scrollEvent.register('begin', (to, element) => {
      if (to === 'contact') {
        this.showForm();
      }
    });
  }

  showForm() {
    this.setState({
      showForm: true,
    });
  }

  handleSubmit(e) {
    e.preventDefault();
    const { form } = this.props;
    const formValues = form.getFieldsValue();
    form.validateFields((err, values) => {
      if (!err) {
        console.log('Received values of form: ', values);
        fetch('/', {
          method: 'POST',
          headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
          body: encode({ 'form-name': 'contact', ...formValues }),
        })
          .then(() => alert('Success!'))
          .catch((error) => alert(error));
      }
    });
  }

  render() {
    const { form } = this.props;
    const { getFieldDecorator } = form;
    const { showForm } = this.state;
    return (
      <>
        {showForm ? (
          <Form
            onSubmit={this.handleSubmit}
            className="contact-form"
            data-netlify="true"
            data-netlify-honeypot="bot-field"
          >
            <input type="hidden" name="bot-field" />
            <FormItem>
              {getFieldDecorator('name', {
                rules: [{ required: true, message: 'Please input your name' }],
              })(<Input placeholder="Full Name" />)}
            </FormItem>
            <FormItem>
              {getFieldDecorator('email', {
                rules: [
                  {
                    type: 'email',
                    message: 'Invalid email',
                  },
                  {
                    required: true,
                    message: 'Please input your email',
                  },
                ],
              })(<Input type="email" placeholder="Email" />)}
            </FormItem>
            <FormItem>
              {getFieldDecorator('phone', {
                rules: [
                  {
                    required: true,
                    message: 'Please input your phone number',
                  },
                ],
              })(<Input placeholder="Phone Number" />)}
            </FormItem>
            <FormItem>
              {getFieldDecorator('company', {
                rules: [
                  {
                    required: true,
                    message: 'Please input your company',
                  },
                ],
              })(<Input placeholder="Company Name" />)}
            </FormItem>
            <FormItem>
              {getFieldDecorator('message', {
                rules: [
                  {
                    required: true,
                    message: 'Please input your message',
                  },
                ],
              })(
                <TextArea
                  style={{ minHeight: 32 }}
                  placeholder="Please input your message"
                  rows={4}
                />,
              )}
            </FormItem>
            <FormItem>
              <Button
                type="primary"
                htmlType="submit"
                className="login-form-button"
                size="large"
              >
                Send Message
              </Button>
            </FormItem>
          </Form>
        ) : (
          <Button onClick={this.showForm} type="primary" size="large">
            Contact Us
          </Button>
        )}
      </>
    );
  }
}

export default Form.create()(ContactForm);
