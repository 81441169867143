import React from 'react';
import { Layout, Row, Col } from 'antd';
import { Link } from 'gatsby';
import { Element } from 'react-scroll';
import LightLogo from '../images/irc-logo-light.svg';
import FilingRampLightLogo from '../images/filing-ramp-logo-light.svg';
import Demo from '../components/images/demo';
import IPhone from '../components/images/iphone';
import IPad from '../components/images/ipad';
import ContactForm from './contactForm';
// import './footer.less';

const { Footer } = Layout;

const FooterComponent = ({ ...props }) => {
  const { children } = props;
  return (
    <div className="footer-container">
      <Element name="contact" className="contact-us">
        <div className="footer-wrapper">
          <Row gutter={32}>
            <Col xs={24} sm={12}>
              <div className="title-text">Introducing FilingRamp</div>
              <p>
                IRC is proud to introduce FilingRamp, a web + mobile app that
                provides insurance companies real-time status of filing projects
                in a clean, user-friendly interface.{' '}
                <strong>
                  <em>Instantly</em>
                </strong>{' '}
                access the details you need on your next filing project.
              </p>
              <ContactForm />
            </Col>
            <Col xs={0} sm={{ span: 11, push: 1 }} className="irc-demo-img">
              <IPad />
              <div className="irc-demo-iphone">
                <IPhone />
              </div>
            </Col>
          </Row>
        </div>
      </Element>
      <Footer>
        <div className="footer-wrapper">
          <Row>
            <Col sm={24} md={12}>
              <h2 className="footer-logo">
                <img src={LightLogo} alt="" />
              </h2>
              <p>
                <a
                  href="https://www.filingramp.com/"
                  className="filingramp-link"
                >
                  <img src={FilingRampLightLogo} alt="FilingRamp" />
                </a>
              </p>
              <p className="copyright">
                Copyright &copy; { new Date().getFullYear() } Insurance Regulatory Consultants LLC.
                <br />
                All rights reserved.
              </p>
            </Col>
            <Col sm={24} md={12}>
              <Row>
                <Col sm={24} md={12}>
                  <h4>Company</h4>
                  <ul>
                    <li>
                      <Link to="/about">About Us</Link>
                    </li>
                    <li>
                      <Link to="/services">Services</Link>
                    </li>
                  </ul>
                </Col>
                <Col sm={24} md={12}>
                  <h4>Get in touch</h4>
                  <ul>
                    <li>
                      <a href="mailto:contact@ircllc.com">contact@ircllc.com</a>
                    </li>
                    <li>+1 212 571-3989</li>
                    <li>
                      PO Box 33, 69 Montgomery Street
                      <br />
                      Jersey City, NJ 07303
                    </li>
                  </ul>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </Footer>
    </div>
  );
};

export default FooterComponent;
